import React, {useEffect} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useMedical} from "../../context/medical/MedicalProvider";
import {Link} from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import PdcHiddenForm from "./PDCHiddenForm";
const MedicalNavigation = () => {

    const {
        displayHeader,facilityId,user,
        logout,getInfo,getUser,handleChat
    } = useMedical();

    useEffect(() => {
       getInfo();
       getUser();
    },[])


    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a
            href=""
            ref={ref}
            onClick={e => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {children}
        </a>
    ));

    const NavMenu = () => {
        return <ul>
            <li>
                {user ?
                    <a onClick={() => document.getElementById('pdcPopulate').submit()}>
                    Make a Payment
                </a> :
                    <Link to={'/log-in'}>
                Make a Payment
            </Link>
            }
            </li>
            <li>
                <Link to={`${user ? '/insuranceinformation' : '/log-in'}`}>
                    <FontAwesomeIcon icon={['fa','globe']}/> Update Insurance Information
                </Link>
            </li>
            <li>
                <Link to="/ask"><FontAwesomeIcon icon={['fa','headphones']}/> Contact Us</Link></li>
            <li>
                <Link to="/faqs"><FontAwesomeIcon icon={['fa','info-circle']}/> FAQ</Link>
            </li>
            <li>
                {
                    user ? <div className={'logout'} onClick={logout}> <FontAwesomeIcon icon={['fa','power-off']}/> Sign Out</div> :
                        <Link to="/log-in"><FontAwesomeIcon icon={['fa','user']}/> Sign In</Link>
                }
            </li>
        </ul>
    }

    return <div className="medicalHeader">
        { displayHeader &&
        <>
            <PdcHiddenForm/>
            <Link to={`/facility/${facilityId}`}>
                <FontAwesomeIcon icon={['fa','home']} size={'2x'} color={"#ffffff"}/>
            </Link>
            <Dropdown className={'hamburger'}>
                <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                    <FontAwesomeIcon className={'hamburger'} size={'2x'} icon={['fa','bars']} color={"#ffffff"}/>
                </Dropdown.Toggle>

                <Dropdown.Menu variant={'primary'} className={'dropdown-menu'}>
                    <Dropdown.Item variant={'primary'} eventKey="1">
                        <a onClick={() => document.getElementById('pdcPopulate').submit()}>
                        Make a Payment
                        </a>
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="2">
                        <Link to={`${user ? '/insuranceinformation' : '/log-in'}`}>
                            <FontAwesomeIcon icon={['fa','globe']}/> Update Insurance Information
                        </Link>
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="3">
                        <Link to="/ask"><FontAwesomeIcon icon={['fa','headphones']}/> Contact Us</Link>
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="4">
                        <Link to="/faqs"><FontAwesomeIcon icon={['fa','info-circle']}/> FAQ</Link>
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="5">
                        {
                            user ? <div className={'logout'} onClick={logout}> <FontAwesomeIcon icon={['fa','power-off']}/> Sign Out</div> :
                                <Link to="/log-in"><FontAwesomeIcon icon={['fa','user']}/> Sign In</Link>
                        }
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            <NavMenu/>
        </>
        }
    </div>
};

export default MedicalNavigation;