import React, {useEffect} from 'react';
import Alert from 'react-bootstrap/Alert';
import {useTranslation} from "react-i18next";
import {PAGE_LOAD, SSO_CONTEXT} from "../ErrorCodes";
import {HC_INTERFACE_LOG} from '../utils/params';
import axios from "axios";
import LeftNavigationBar from "../LeftNavigationBar";

const ErrorFallback = ({error, resetErrorBoundary,error_code = 2001, loggedIn=false,route = ''}) => {

    //2001 is the default error code for errors that are caught in a try/catch block
    const {t} = useTranslation('common')
    useEffect(async () => {

            const errorObj = {
                error_code,
                message: error.message,
                stack: error.stack,
                route,
                timestamp: new Date()
            };

            await axios.post(`${HC_INTERFACE_LOG}`, errorObj)
                .then(() => {
                    console.log(errorObj.message);
                })
                .catch((e) => { console.error('Failed to log Message', e); });
    },[]);

    if (error.code === 'ECONNABORTED') error_code = error.code;

    switch(error_code)
    {
        case SSO_CONTEXT:
            return (
                <div>
                    <Alert variant={'warning'}>
                        {t('sso.error.title')}
                    </Alert>
                    <p>{t('sso.error.message')}</p>
                </div>
            );
        case PAGE_LOAD :
            return (
                <div id="mainGrid">
                    {loggedIn && <LeftNavigationBar/>}
                    <div id="rightConMain">
                        <div id={"contentBuffer"}>
                            <Alert variant={'danger'}>
                                {t('alerts.error.boundary',{error_code})}
                            </Alert>
                        </div>
                    </div>
                </div>
            )
        case "ECONNABORTED":
            return (
                <div id="mainGrid">
                    {loggedIn && <LeftNavigationBar/>}
                    <div id="rightConMain">
                        <div id={"contentBuffer"}>
                            <Alert variant={'danger'}>
                                {t('alert.timeout')}
                            </Alert>
                        </div>
                    </div>
                </div>
            )
        default:
            return (
                <Alert variant={'danger'}>
                    {t('alerts.error.boundary',{error_code})}
                </Alert>
            );

    }
};

export default ErrorFallback;