import React, {createContext, useContext, useReducer} from 'react';
import {useApi} from "../hooks/useApi";
import Cookies from "universal-cookie/lib";

const cookies = new Cookies();
const SubClientContext = createContext();
SubClientContext.displayName = 'SubClientContext'

const useSubClients = () =>
{
    const context = useContext(SubClientContext);
    if (!context) throw new Error('useSubClients must be used within an SubClientProvider');
    return context;
}

const SubClientReducerTypes = {
    SET_SUBCLIENT: 'SET_SUBCLIENT',
    SET_SUBCLIENT_LIST: 'SET_SUBCLIENT_LIST',
}

const SubClientReducer = (state,action) =>
{
    switch (action.type)
    {
        case 'SET_SUBCLIENT_LIST': return {...state, subClients: action.payload}
        case 'SET_SUBCLIENT':
            cookies.set('subDropdown', action.payload.displayName, {path: '/'});
            return {
                ...state,
                selectedSubClient: action.payload,
                subClientTitle: action.payload.displayName
            }
        default: throw new Error("Unsupported Type: " + action.type);
    }
}

const SubClientProvider = props =>
{
    const [state,dispatch] = useReducer(SubClientReducer,{
        subClients: [],
        selectedSubClient: {},
        subClientTitle: ''
    });

    const {api} = useApi();

    const getSubClients = async () =>
    {
        try
        {
            const {data} = await api({
                route: 'org/subclients',
                config: {blackout: false}
            });

            dispatch({
                type: SubClientReducerTypes.SET_SUBCLIENT_LIST,
                payload: data.names
            });

            // returns the parentOrg to be set as the initial option
            const parent = data.names.filter(org => org.extOrgId === '0');
            const subClientCookie = cookies.get('subDropdown',{path:'/'});

            //sets the subClient dropdown to the subClient cookie if there is one
            if (subClientCookie !== undefined)
            {
                const subClient = data.names.filter(org => org.displayName === subClientCookie);
                await setSubClient(subClient[0],false)
            }
            else
            {
                await setSubClient(parent[0])
            }
        }
        catch (e)
        {
            console.error(e)
        }
    }

    const setSubClient = async (subClient) =>
    {
        dispatch({
            type: SubClientReducerTypes.SET_SUBCLIENT,
            payload: subClient
        });
    }

    const value = {
        subClients: state.subClients,
        subClientTitle: state.subClientTitle,
        selectedSubClient: state.selectedSubClient,
        getSubClients,
        setSubClient
    }

    return (
        <SubClientContext.Provider value={value} {...props} />
    );
};

export {SubClientProvider, useSubClients};