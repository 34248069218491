import React from 'react';
import {NavLink} from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import useHcNavigation from "../hooks/useHcNavigation";
import Cookies from "universal-cookie/lib";
import useUser from "../hooks/useUser";
const cookies = new Cookies();
const CustomerNavigation = (props) =>
{
    const {t,features,logout} = useHcNavigation();
    const {user} = useUser();
    return (
        <nav>
            <ul>
                <li>
                    <NavLink to="/documents" 
                             className={({ isActive }) => "leftNavConLinks mobileShow" + (isActive ? " active-nav" : "")} 
                             onClick={props.menuCallback}>
                        <FontAwesomeIcon icon={['fas', 'newspaper']}/> <span className="navLabel">{t('main.link.documents')}</span>
                    </NavLink>
                </li>
                {
                    features.includes('transactions') &&
                    <li>
                        <NavLink to="/transactions" 
                                 className={({ isActive }) => "leftNavConLinks mobileShow" + (isActive ? " active-nav" : "")} 
                                 onClick={props.menuCallback}>
                            <FontAwesomeIcon icon={['fas', 'tasks']} /> <span className="navLabel">{t('main.link.transactions')}</span>
                        </NavLink>
                    </li>
                }
                {
                    features.includes('proxy_users') && user.userType !== 'proxy' &&
                    <li>
                        <NavLink to="/proxyusers" className="leftNavConLinks">
                            <FontAwesomeIcon icon={['fas', 'id-card-alt']} /> <span className="navLabel">{t('main.link.proxy')}</span>
                        </NavLink>
                    </li>
                }
                    <li>
                        <div className="leftNavConLinks" onClick={logout}>
                            <FontAwesomeIcon icon={['fas', 'sign-out-alt']}/> <span className="navLabel">Logout</span>
                        </div>
                    </li>
            </ul>
        </nav>
    );
}

export default CustomerNavigation;