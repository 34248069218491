import React, {createContext, useContext, useState} from 'react';

import '../../css/medical/css/utilities.css';
import Cookies from "universal-cookie/lib";

const DemoContext = createContext();


const useDemo = () => {
    const context = useContext(DemoContext);
    if (!context) throw new Error('useDemo must be used within a DemoProvider');
    return context;
}

const DemoProvider = props => {

    const [loading,setLoading] = useState(true);
    const cookies = new Cookies();


    const logout = () => {
        cookies.remove("demoToken");
    }

    const value = {
       loading,logout,cookies
    }

    return <DemoContext.Provider value={value} {...props}/>
};

export {DemoProvider,useDemo}