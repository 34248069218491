import React, {Component, useContext, useState} from 'react';
import {NavLink} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useHcNavigation from "../hooks/useHcNavigation";
import Routes from "../types/Routes"
const ClientNavigation = (props) =>  {

    const {t,settings,hasPermission,logout,features,user} = useHcNavigation();
    const {clientShowOrgs} = settings;
    const {MY_MAIL_MAIN} = Routes;
    return (
        <nav>
            {
                user &&
                clientShowOrgs !== undefined &&
                <ul>
                    {
                        features.includes('dashboard') && features.includes('widgets') &&
                        hasPermission('dashboard_view') &&
                        <li>
                            <NavLink to="/dashboard"
                                     className={({ isActive }) => "leftNavConLinks" + (isActive ? " active-nav" : "")}
                                     onClick={props.menuCallback}>
                                <FontAwesomeIcon icon={['fas', 'home']} /> <span className="navLabel">{t('main.link.dashboard')}</span>
                            </NavLink>
                        </li>
                    }
                    {
                        features.includes('accounts') &&
                        hasPermission('accounts_view') &&
                        <li>
                            <NavLink to="/accounts"
                                     className={({ isActive }) => "leftNavConLinks" + (isActive ? " active-nav" : "")}
                                     onClick={props.menuCallback}>
                                <FontAwesomeIcon icon={['fas', 'money-bill-alt']} /> <span className="navLabel">{t('main.link.accounts')}</span>
                            </NavLink>
                        </li>
                    }
                    {
                        features.includes('documents') &&
                        hasPermission('documents_view') &&
                        <li>
                            <NavLink to="/documents"
                                     className={({ isActive }) => "leftNavConLinks" + (isActive ? " active-nav" : "")}
                                     onClick={props.menuCallback}>
                                <FontAwesomeIcon icon={['fas', 'newspaper']} /> <span className="navLabel">{t('main.link.documents')}</span>
                            </NavLink>
                        </li>
                    }
                    {
                        features.includes('my_mail') &&
                        hasPermission('my_mail_view') &&
                        <li>
                            <NavLink to={MY_MAIL_MAIN}
                                     className={({ isActive }) => "leftNavConLinks" + (isActive ? " active-nav" : "")}
                                     onClick={props.menuCallback}>
                                <FontAwesomeIcon icon={['fas', 'envelopes-bulk']} /> <span className="navLabel">{t('main.link.mailTracking')}</span>
                            </NavLink>
                        </li>
                    }
                    {
                        features.includes('jobs') &&
                        hasPermission('jobs_view') &&
                        <li>
                            <NavLink to="/jobs"
                                     className={({ isActive }) => "leftNavConLinks" + (isActive ? " active-nav" : "")}
                                     onClick={props.menuCallback}>
                                <FontAwesomeIcon icon={['fas', 'print']} /> <span className="navLabel">{t('main.link.jobs')}</span>
                            </NavLink>
                        </li>
                    }
                    {
                        (
                            (features.includes('email') && hasPermission('emails_view')) ||
                            (features.includes('blasts') && hasPermission('blasts_view')) ||
                            (features.includes('sms') && hasPermission('sms_view'))
                        ) &&
                        <li>
                            <NavLink to="/messaging"
                                     className={({ isActive }) => "leftNavConLinks" + (isActive ? " active-nav" : "")}
                                     onClick={props.menuCallback}>
                                <FontAwesomeIcon icon={['fas', 'envelope']}/>
                                <span className="navLabel">{t('main.link.messaging')}</span>
                            </NavLink>
                        </li>
                    }
                    {
                        features.includes('reports') &&
                        hasPermission('reports_view') &&
                        <li>
                            <NavLink to="/reports"
                                     className={({ isActive }) => "leftNavConLinks" + (isActive ? " active-nav" : "")}
                                     onClick={props.menuCallback}>
                                <FontAwesomeIcon icon={['fas', 'chart-pie']} /> <span className="navLabel">{t('main.link.reports')}</span>
                            </NavLink>
                        </li>
                    }
                    {
                        features.includes('customers') &&
                        hasPermission('customers_view') &&
                        <li>
                            <NavLink to="/customers"
                                     className={({ isActive }) => "leftNavConLinks" + (isActive ? " active-nav" : "")}
                                     onClick={props.menuCallback}>
                                <FontAwesomeIcon icon={['fas', 'user']} /> <span className="navLabel">{t('main.link.customers')}</span>
                            </NavLink>
                        </li>
                    }
                    {
                        features.includes('proxy_users') &&
                        hasPermission('proxy_view') &&
                        <li>
                            <NavLink to="/proxyusers" className="leftNavConLinks">
                                <FontAwesomeIcon icon={['fas', 'id-card-alt']} /> <span className="navLabel">{t('main.link.proxy')}</span>
                            </NavLink>
                        </li>
                    }
                    {
                        clientShowOrgs &&
                        features.includes('orgs') &&
                        hasPermission('orgs_view') &&
                        <li>
                            <NavLink to="/orgs"
                                     className={({ isActive }) => "leftNavConLinks" + (isActive ? " active-nav" : "")}
                                     onClick={props.menuCallback}>
                                <FontAwesomeIcon icon={['fas', 'tasks']}/> <span className="navLabel">{t('main.link.orgs')}</span>
                            </NavLink>
                        </li>
                    }
                    {
                        features.includes('staff') &&
                        hasPermission('staff_view') &&
                        <li>
                            <NavLink to="/staff"
                                     className={({ isActive }) => "leftNavConLinks" + (isActive ? " active-nav" : "")}
                                     onClick={props.menuCallback}>
                                <FontAwesomeIcon icon={['fas', 'id-card-alt']} /> <span className="navLabel">{t('main.link.staff')}</span>
                            </NavLink>
                        </li>
                    }
                    {
                        features.includes('usergroups') &&
                        hasPermission('usergroups_view') &&
                        <li>
                            <NavLink to="/usergroups"
                                     className={({ isActive }) => "leftNavConLinks" + (isActive ? " active-nav" : "")}
                                     onClick={props.menuCallback}>
                                <FontAwesomeIcon icon={['fas', 'user-friends']} /> <span className="navLabel">{t('main.link.usergroups')}</span>
                            </NavLink>
                        </li>
                    }
                    {
                        features.includes('event_notifications') &&
                        hasPermission('event_notification_view') &&
                        <li>
                            <NavLink to="/notifications"
                                     className={({ isActive }) => "leftNavConLinks" + (isActive ? " active-nav" : "")}
                                     onClick={props.menuCallback}>
                                <FontAwesomeIcon icon={['fas', 'circle-exclamation']} /> <span className="navLabel">{t('main.link.notifications')}</span>
                            </NavLink>
                        </li>
                    }
                    {
                        features.includes('ticketing') &&
                        <li>
                            <NavLink to={'https://clientsupport.account.tools'}
                                     target="_blank" rel="noopener noreferrer"
                                     className={({isActive}) => "leftNavConLinks" + (isActive ? " active-nav" : "")}
                            >
                                <FontAwesomeIcon icon={['fas', 'ticket']}/><span
                                className={'navLabel'}>{t('main.link.ticket')}</span>
                            </NavLink>
                        </li>
                    }
                    {
                        features.includes('smart_campaign') &&
                        <li>
                            <NavLink to={'https://smart.account.tools'}
                                     target="_blank" rel="noopener noreferrer"
                                     className={({isActive}) => "leftNavConLinks" + (isActive ? " active-nav" : "")}
                            >
                                <FontAwesomeIcon icon={['fas', 'compass']}/><span
                                className={'navLabel'}>{t('main.link.scm')}</span>
                            </NavLink>
                        </li>
                    }
                    <li>
                        <div className="leftNavConLinks" onClick={logout}>
                            <FontAwesomeIcon icon={['fas', 'sign-out-alt']}/> <span className="navLabel">Logout</span>
                        </div>
                    </li>
                </ul>
            }
        </nav>
    );

}

export default ClientNavigation;