import React, {useState} from 'react';
import Cookies from "universal-cookie/lib";
import AdminNavigation from "./components/AdminNavigation";
import ClientNavigation from "./components/ClientNavigation";
import CustomerNavigation from "./components/CustomerNavigation";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import useUser from "./hooks/useUser";
import './css/navigation.scss';
import ProxyNavigation from "./components/ProxyNavigation";

const LeftNavigationBar = (props) => {
    const cookies = new Cookies();
    const {user} = useUser();

    const [menuState, setMenuState] = useState(false);

    let mobileClass = "mobileHide";

    if (menuState) {
        mobileClass = "";
    }

    const mobileMenuClick = () => {
        if (menuState) {
            setMenuState(false);
        } else {
            setMenuState(true);
        }
    };

    const getNavClass = () => {
        const interfaceType = cookies.get('interfaceType');

        switch (interfaceType) {
            case "admin":
                return (<AdminNavigation menuCallback={mobileMenuClick}/>);
            case "client":
                return (<ClientNavigation menuCallback={mobileMenuClick}/>);
            case "customer":
                return (<CustomerNavigation menuCallback={mobileMenuClick}/>);
            case "proxy":
                return (<ProxyNavigation menuCallback={mobileMenuClick}/>);
            default:
                console.error("Invalid interface type", interfaceType);
        }
    }

    const getName = () => {
        if ((user !== undefined && user !== null) && (user.lastName !== undefined)) {
            if (user.lastName !== undefined) return user.lastName;
        } else if ((user !== undefined && user !== null) && (user.userType === 'proxy')) {
            return "Proxy User";
        }
    }

    return (
        <div id="leftNavConOuter">
            <div id="leftNavMobileCon">
                <div id="mobileMenuIcon" onClick={mobileMenuClick}>
                    <FontAwesomeIcon icon={['fas', 'bars']}/>
                </div>
                <div id="mobileMenuRight">
                    <div className="headerBarLogo"><img className="logo" src={props.logo} alt="Logo"/></div>
                </div>
            </div>
            <div id="leftNavCon" className={mobileClass}>
                <div className="hc-profile-link">
                    <div><Link to='/profile' id={"headerName"}><span className={"bold"}>{getName()}</span></Link></div>
                    {user && !user.adminLogin &&
                        <div><Link to='/profile' id={"headerProfileLink"}>My Profile</Link></div>}
                </div>
                <hr/>
                {getNavClass()}
            </div>
        </div>
    );
};

export default LeftNavigationBar;