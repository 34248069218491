import React from 'react';
import Alert from 'react-bootstrap/Alert';
import useAlert from "./hooks/useAlert";
const Alerts = () => {

    const {alert,removeAlert} = useAlert();
    const {msg,type,dismissible} = alert
    // todo alert appears off screen when paged is scrolled to the bottom
    return (
        <>
        {
        msg !== '' &&
        <Alert variant={type} onClose={removeAlert} dismissible={dismissible}>
            {msg}
        </Alert>
        }
        </>
    );
}


export default Alerts;