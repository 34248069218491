import React, {useContext} from 'react';
import UserContext from "../context/user/userContext";

const useUser = () => {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error('useUser must be used with UserProvider')
    }
    return context;
}

export default useUser;