import React, {useContext} from 'react';
import AlertContext from "../context/alert/alertContext";

const useAlert = () => {
    const context = useContext(AlertContext);
    if (!context) {
        throw new Error('useAlert must be used with an alert Provider')
    }
    return context;
};

export default useAlert;