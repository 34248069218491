import React from 'react';
import {NavLink} from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import useHcNavigation from "../hooks/useHcNavigation";

const ProxyNavigation = (props) =>
{
    const {t,features,logout} = useHcNavigation();

    return (
        <nav>
            <ul>
                <li>
                    <NavLink to="/documents"
                             className={({ isActive }) => "leftNavConLinks mobileShow" + (isActive ? " active-nav" : "")}
                             onClick={props.menuCallback}>
                        <FontAwesomeIcon icon={['fas', 'newspaper']}/> <span className="navLabel">{t('main.link.documents')}</span>
                    </NavLink>
                </li>
                <li>
                    <div className="leftNavConLinks" onClick={logout}>
                        <FontAwesomeIcon icon={['fas', 'sign-out-alt']}/> <span className="navLabel">Logout</span>
                    </div>
                </li>
            </ul>
        </nav>
    );
}

export default ProxyNavigation;