import React, {useState, useEffect} from 'react';
import '../../css/App.scss';

import {ErrorBoundary, useErrorHandler} from "react-error-boundary";

import {ssoApi} from "../../utils/api";
import ErrorFallback from "../ErrorFallback";
import {SSO_CONTEXT} from "../../ErrorCodes";
import {reactData} from "../../utils/reactData";


const SSOError = () => {
    return (
        <ErrorBoundary fallbackRender={props => <ErrorFallback {...props} error_code={SSO_CONTEXT}/>}>
            <div>
                <h3>No documents are currently available</h3>
            </div>
        </ErrorBoundary>
    );
}

export default SSOError