import React, {useContext} from 'react';
import axios from "axios";
import {HC_API} from './params'
import Cookies from "universal-cookie/lib";
import {reactData} from "./reactData";
import UserContext from "../context/user/userContext";
import useUser from "../hooks/useUser";


const cookies = new Cookies();

const hcatApi = async (route, data={},headers={},config={},method='post') => {
    // Full screen setting will cause the loader to cover/block the main navigation

    const {logout} = useUser();

    let blackoutClass = 'blackoutDefault';

    if (config?.loader?.full_screen) {
        blackoutClass = 'blackoutFull';
    }

    try {
        const config = {
            headers
        };
        if (!('Content-Type' in config.headers)) config.headers['Content-Type'] ='application/json';


        //const {setLoading, removeLoading} = loadingContext;
        //document.body.classList.add('test');

        let blackout = config.blackout || true;
        let blackoutElement;

        if(!document.getElementById("blackout")) {
            blackout = false;
        }

        if(blackout) {
            blackoutElement = document.getElementById("blackout");
        }


        const url = HC_API + route;

        data.remote_uri = reactData.host;

        switch (method) {
            case 'get':
            case 'GET':
                return await axios.get(url, config);
            case 'post':
            case 'POST':
                axios.interceptors.request.use((config) => {
                    if(blackout) {
                        blackoutElement.classList.add(blackoutClass);
                    }
                    return config;
                }, (error) => {
                    if(blackout) {
                        blackoutElement.classList.remove(blackoutClass);
                    }
                    return Promise.reject(error);
                });

                axios.interceptors.response.use((response) => {
                    if(blackout) {
                        blackoutElement.classList.remove(blackoutClass);
                    }
                    return response;
                }, (error) => {
                    if(blackout) {
                        blackoutElement.classList.remove(blackoutClass);
                    }
                    return Promise.reject(error);
                });

                console.log("Posting Data", data)
                return await axios.post(url, data, config);

            default:
                console.error('invalid method passed: '+ method)
                return;
        }
    } catch (e)
    {
        if (e.status !== undefined && e.status == 403)
            logout();

        console.error('API Error: ', e.status, e);
    }
}
export const ssoApi = async (ssoId, route,data={},headers={},config={},method='post') => {
    if (!('Authorization' in headers)) {
        headers.Authorization = 'SSO ' + ssoId;
    }
    return hcatApi(route, data, headers, config, method)
}