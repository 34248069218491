import React from 'react';
import '../../css/demo/demo.scss'

const DemoFooter = () => {
    return (
        <footer>
            <small>© Bank 2022. All rights reserved.</small>
        </footer>
    );
}

export default DemoFooter;