import React,{ useState, useEffect } from 'react';
import Cookies from "universal-cookie/lib";
import {utcToZonedTime, zonedTimeToUtc, format} from 'date-fns-tz';

const cookies = new Cookies();

export const convertToBlob = (base64String,ext) => {
    //atob decodes the base 64 string
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);

    //using charCodeAt to create an array of byte values
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    //converting the byte values to represent an array of 8-bit unsigned integers
    const byteArray = new Uint8Array(byteNumbers);

    //using byteArray to create a Blob so pdf can be downloaded
    return new Blob([byteArray], {type: `application/${ext}`});

}

export const getTodaysDate = () => {
    return new Date().toLocaleString('en-US',{hour12:false})
}


export const getBase64 = async file => {
    return new Promise(resolve => {
        let fileInfo;
        let baseURL = "";
        // Make new FileReader
        let reader = new FileReader();

        // Convert the file to base64 text
        reader.readAsDataURL(file);

        // on reader load something...
        reader.onload = () => {
            // Make a fileInfo Object
            //console.log("Called", reader);
            baseURL = reader.result;
            //console.log(baseURL);
            resolve(baseURL);
        };
        //console.log(fileInfo);
    });
};

/**
 * Creates an 'a' tag to download file; removes link once download is finished
 * @desc a method for downloading PDF files
 * @param base64String the pdf encoded in a base64 string
 * @param title name of the file
 * @param ext file extension
 */
export const downloadFile = (base64String,title,ext) =>
{
    const link = document.createElement("a");
    link.href = URL.createObjectURL(convertToBlob(base64String, ext))
    link.setAttribute('download', `${title}.${ext}`);
    document.body.appendChild(link)
    link.click();
    link.parentNode.removeChild(link);
}

export const convertToLocalTime = dateString => {
    let theDate = new Date(Date.parse(dateString + ' UTC'))
    return theDate.toLocaleString();
}

export const LoaderAnimation = () => {

    let loadingAnimation = '../assets/images/loading.gif';

    return (
        <>
            <div className={'loaderAnimation'}>
                <img src={loadingAnimation} alt="loading" />
            </div>
        </>
    )
}

/**
 * Adds a + symbol to emails that have been removed and replaced with a space(%20) through a cookie
 * @param email
 */
export const handlePlusEmails = email => {
    return email.includes(' ') ? email.replace(' ', '+') : email;
}

export const isValidEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
}

export const generateStringId = () => {
    return  Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
}

const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

export const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

export const chartTheme = () => {

    const theme = {
        light: {
            palette: '#4798D1,#333333,#204264,#ccc,#476279,#636363',
            bgColor: '#FFFFFF',
            textColor: '#333',
            toolTipBgColor: "#FFFFFF"
        },
        dark: {
            palette: '#4798D1,#643d64,#204264,#606060,#e3e3e3',
            bgColor: '#252525',
            textColor: '#FFFFFF',
            toolTipBgColor: "#333"
        }
    }

    const prefCookie = cookies.get('preferences') || {};
    const currentTheme = prefCookie.theme || 'light';

    return theme[currentTheme];
}

export const isObjEmpty = obj => {
    return obj === null || Object.getOwnPropertyNames(obj).length === 0
}


export const formatReactDatePickerValue = (d, style) => {
    let year = d.getFullYear();
    let month = d.getMonth() + 1 < 10 ? '0' + (d.getMonth()+1) : d.getMonth();
    let day = d.getDate() < 10 ? '0' + d.getDate() : d.getDate();

    switch (style) {
        case 0:
            return month + "-" + day + "-" + year;
        default:
            return year + "-" + month + "-" + day;
    }

}

export const convertDateFormat = strDate => {
    const userPrefs = cookies.get('preferences')

    const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone || null;
    let tz = userPrefs?.timezone !== '' &&  userPrefs?.timezone !== undefined ?  userPrefs?.timezone : localTimeZone;
    let dateVal = strDate + ' UTC';
    const date = new Date(dateVal);
    const zonedDate = utcToZonedTime(date, tz);

    const pattern = 'MM/dd/yyyy hh:mm:ss aaa';
    const output = format(zonedDate, pattern, { timeZone: tz })
    return output;
}
