import React from 'react';
import ModalWindow from "../../ModalWindow";
import Timer from "../Timer";
import {useMedical} from "../../context/medical/MedicalProvider";

const MedicalUnAuthRoute = ({component:Component}) => {
    const {user} = useMedical();
    return (
        <div>
            { user &&
                <>
                    <ModalWindow/>
                    <Timer medicalTimer/>
                </>
            }
            <Component/>
        </div>
    );
};

export default MedicalUnAuthRoute;