import React, {lazy, useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
//import ClientApp from "./ClientApp";
import {ErrorBoundary} from "react-error-boundary";
import ErrorFallback from "./components/ErrorFallback";
import {reactData} from "./utils/reactData";
import SSOError from "./components/sso/SSOError";
import i18next from "i18next";
import common_en from "./translations/en/common.json";
import common_es from "./translations/es/common.json";
import forgot_en from './translations/en/forgotPassword.json'
import forgot_es from './translations/es/forgotPassword.json'
import HCPage from "./components/HCPage";
import Cookies from "universal-cookie/lib";
import AppProvider from "./context/AppProvider";
import {SsoProvider} from "./context/sso/SSOProvider";
import MedicalApp from "./MedicalApp";
import DemoApp from "./DemoApp";
const SSOApp = React.lazy(() => import("./components/sso/SSOApp").then((module) => ({ default: module.default})));
const cookies = new Cookies();

const App = () => {
    const ssoId = reactData.ssoId;
    const url = window.location.href;
    useEffect( ()  => {

        i18next.init({
            interpolation: { escapeValue: false },  // React already does escaping
            lng: cookies.get('locale') !== undefined ? cookies.get('locale') : 'en',                              // language to use
            resources: {
                en: {
                    common: common_en,               // 'common' is our custom namespace
                    forgotPassword: forgot_en
                },
                es: {
                    common: common_es,
                    forgotPassword: forgot_es
                },
            },
            react : {
                useSuspense: false
            }
        });
    },[]);
    function getBasePage()
    {
        if(ssoId !== '0' &&  ssoId !== null && ssoId !== undefined)
        {
            if(ssoId === '99') {
                return (
                <div className="App">
                    <SSOError/>
                </div>
                );
            }
            else {
                console.log('sso App');
                return (
                    <ErrorBoundary fallbackRender={props => <ErrorFallback {...props}/>} >
                        <AppProvider>
                            <SsoProvider>
                                <div className="App" style={{backgroundColor: "whitesmoke"}}>
                                    <HCPage component={SSOApp}/>
                                </div>
                            </SsoProvider>
                        </AppProvider>
                    </ErrorBoundary>
                );
            }
        }
        else if (url.includes('patient') && url.includes('statement.services')){
            return <MedicalApp/>
        }
        else if (url.includes('statement.services/demo') || url.includes('statement.services/estatements')) {
            return <DemoApp/>
        }
        else {
            //import ClientApp from "./ClientApp";
            const ClientApp = lazy(() => import("./ClientApp"));
            return (

                <ClientApp/>
            );
        }

    }
    return (
        <ErrorBoundary fallbackRender={props => <ErrorFallback {...props}/>}>
            {
                getBasePage()
            }
        </ErrorBoundary>
    );

}
export default App;