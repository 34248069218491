import React, {useContext} from 'react';
import SettingsContext from "../context/settings/settingsContext";

const useSettings = () => {
    const context = useContext(SettingsContext);

    if (!context) {
        throw new Error('useSettings must be used with settingsProvider');
    }

    return context;
};

export default useSettings;