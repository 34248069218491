import React from 'react';
import SettingsState from "./settings/SettingsState";
import {SubClientProvider} from "./SubClientContext";
import {MedicalProvider} from "./medical/MedicalProvider";
import AlertState from "./alert/AlertState";
import ModalState from "./modal/modalState";

const MedicalAppProvider = ({children}) => {
    return (
        <SettingsState>
            <SubClientProvider>
                <MedicalProvider>
                    <ModalState>
                        <AlertState>
                            {children}
                        </AlertState>
                    </ModalState>
                </MedicalProvider>
            </SubClientProvider>
        </SettingsState>
    );
};

export default MedicalAppProvider;