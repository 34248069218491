import React, {useEffect} from 'react';
import '../../css/demo/demo.scss'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DemoLogin from "./DemoLogin";

const DemoIndex = () => {

    return (
        <>
            <section id="primaryContainer">

                <div id="indexInnerContentContainer">

                    <DemoLogin/>

                    <aside>
                        <div>
                            <h2>0% intro APR for up to 21 months
                                with $0 annual fee. Terms apply.</h2>
                            <button>Learn More</button>
                        </div>
                    </aside>
                </div>


            </section>

            <section className="cardContainer">
                <article>
                    <div className="cardHeaderIcon">
                        <FontAwesomeIcon icon={['fas', 'money-check']} />
                        <i className="fa-solid fa-money-check"></i>
                    </div>
                    <div>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur elit nisi, placerat eget
                        imperdiet nec, tempus sed ex. Cras a lorem aliquet, mollis quam tempor, rutrum tortor. Vivamus
                        eu vehicula ipsum. Proin condimentum auctor enim non lobortis. Vivamus quis est massa. Nullam
                        eleifend congue sem vitae pharetra. Cras aliquam velit ipsum, id dapibus neque aliquet sit amet.
                        Nunc suscipit lectus luctus dolor rutrum, in fringilla massa consectetur.
                    </div>
                </article>
                <article>
                    <div className="cardHeaderIcon">
                        <FontAwesomeIcon icon={['fas', 'hand-holding-usd']} />
                    </div>
                    <div>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur elit nisi, placerat eget
                        imperdiet nec, tempus sed ex. Cras a lorem aliquet, mollis quam tempor, rutrum tortor. Vivamus
                        eu vehicula ipsum. Proin condimentum auctor enim non lobortis. Vivamus quis est massa. Nullam
                        eleifend congue sem vitae pharetra. Cras aliquam velit ipsum, id dapibus neque aliquet sit amet.
                        Nunc suscipit lectus luctus dolor rutrum, in fringilla massa consectetur.
                    </div>
                </article>
                <article>
                    <div className="cardHeaderIcon">
                        <FontAwesomeIcon icon={['fas', 'piggy-bank']} />
                    </div>
                    <div>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur elit nisi, placerat eget
                        imperdiet nec, tempus sed ex. Cras a lorem aliquet, mollis quam tempor, rutrum tortor. Vivamus
                        eu vehicula ipsum. Proin condimentum auctor enim non lobortis. Vivamus quis est massa. Nullam
                        eleifend congue sem vitae pharetra. Cras aliquam velit ipsum, id dapibus neque aliquet sit amet.
                        Nunc suscipit lectus luctus dolor rutrum, in fringilla massa consectetur.
                    </div>
                </article>
            </section>


        </>
    )
}

export default DemoIndex;