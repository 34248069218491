export const env = "prod";
export const domainSuffix = '.'+env;
export const HC_API = (env === 'prod' || env === 'prod2' )  ? 'https://interfaceapi.account.tools/' : 'https://interfaceapi.'+env+'.account.tools/';
export const HC_CLIENT = (env === 'prod' || env === 'prod2' ) ? 'hc3connect.io/' : env+'.hc3connect.io/';
export const HC_INTERFACE_LOG =(env === 'prod' || env === 'prod2' )  ? 'https://interface.account.tools/log' : 'https://interface.'+env+'.account.tools/log';

export const REPORT_EXPORT_LIMIT = 5000;

//export const DEMO_CLIENT = "8";
export const DEMO_CLIENT = "4274";
//export const DEMO_ACCOUNTS="DEMO626992,DEMO004584";
export const DEMO_ACCOUNTS="8516998,5949601";

/** Chat Parameters **/
export const CHAT_ENV = 'prod-usw2'
export const CHAT_DEPLOYMENT = '62ae5db1-8976-4a93-bb47-01844721a99c'