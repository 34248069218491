import React from 'react';
import {useMedical} from "../../context/medical/MedicalProvider";

const PdcHiddenForm = () => {

    const {
        facilityId,user,accountNumber,
        paymentUrl,displayName
    } = useMedical();

    return (
        <form className={'pdc-form'} id="pdcPopulate" action={paymentUrl+facilityId+"/"} method="post" target="_blank">
            {
                ( accountNumber !== '0' || accountNumber !== '' ) &&
                user &&
                <>
                    <input type="hidden" name="firstName" value={user.firstName} />
                    <input type="hidden" name="lastName" value={user.lastName} />
                    <input type="hidden" name="accountNumber" value={accountNumber} />
                    <input type="hidden" name="emailAddress" value={user.emailAddress} />
                    <input type="hidden" name="phoneNumber" value={user.phoneNumber} />
                    <input type="hidden" name="paymentAmount" value={user.balance ?? ''} />
                </>
            }
            <input type="hidden" name="roundTrip[facility]" value={displayName} />
            <input type="hidden" name="location" value={displayName} />
        </form>
    );
};

export default PdcHiddenForm;