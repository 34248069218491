import React, {useEffect} from 'react';
import '../../css/demo/demo.scss'
import {useApi} from "../../hooks/useApi";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import Cookies from "universal-cookie/lib";
import {DEMO_ACCOUNTS, DEMO_CLIENT} from "../../utils/params";

const Estatements = () => {

    const {api} = useApi();
    const navigate = useNavigate();
    const cookies = new Cookies();
    const ssoData = {
        accountNumber: DEMO_ACCOUNTS,
        client: DEMO_CLIENT,
    };

    useEffect(() => {
        if (cookies.get("demoToken") === "true") {
            api({
                route: 'demo/ssotest',
                data: ssoData,
                successMsg: 'Form submitted!',
                errorMsg: 'Error submitting form'
            })
                .then(
                    (res) => {
                        console.log("API Res: ", res)
                        const handshake = res.data.handshake;
                        const querystring = require('querystring');
                        axios.post(res.data.url, querystring.stringify(handshake))
                            .then((response) => {
                                const iframe = document.getElementById('sso_iframe');
                                iframe.style.width = '900px';
                                iframe.style.height = `1000px`;
                                iframe.contentWindow.document.open();
                                iframe.contentWindow.document.write(response.data);
                                iframe.contentWindow.document.close();
                            })
                            .catch((error) => {
                                handleError(error);
                            });
                    }
                ).catch((error) => {
                console.log("Error: ", error)
            });
        } else {
            console.log("Fell into else")
            navigate("/demo");
        }


    }, []);

    return (
        <>
            <section>
                <iframe id="sso_iframe" src="about:blank"/>
            </section>
        </>
    );
}

export default Estatements;