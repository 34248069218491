import React, {useEffect, useState} from 'react';
import LandingHeader from "./LandingHeader";
import '../../css/medical/css/selectSearch.css'
import {useApi} from "../../hooks/useApi";
import {useMedical} from "../../context/medical/MedicalProvider";
import {useNavigate} from "react-router-dom";
import useDateDropdowns from "../../hooks/useDateDropdowns";
import Form from 'react-bootstrap/Form';
import useAlert from "../../hooks/useAlert";
import Alerts from "../../Alerts";
import SelectSearch, {fuzzySearch} from "react-select-search";
import Cookies from "universal-cookie/lib";
const SignIn = () => {

    const {facilityId,setFacilityInfo,handleValidatedUserData} = useMedical();
    const {api} = useApi();
    const navigate = useNavigate();
    const {setAlert,removeAlert} = useAlert();
    const cookies = new Cookies();
    const {
        days,months,years,
        selectedMonth,setSelectedMonth,
        selectedDay,setSelectedDay,
        selectedYear,setSelectedYear,
    } = useDateDropdowns();

    const handleSubmit = async e => {
        try {
            e.preventDefault();
            const {accountNumber,name,phoneNumber} = e.target.elements;
            const params = {
                accountNumber:accountNumber.value,
                day:selectedDay,
                month:selectedMonth,
                year:selectedYear
            };

            if (params['accountNumber'] === '' || params['day'] === '' ||
                params['month'] === '' || params['year'] === ''
            ) {
                setAlert('Please fill out the required fields','danger');
            } else {
                if (name.value !== '') params['name'] = name.value;
                if (phoneNumber.value !== '') params['phoneNumber'] = phoneNumber.value;
                const {data} = await api({route:'medical/login',data: params});
                if (data.validated) {
                    handleValidatedUserData(data);
                    const dataFacilityId = data.extOrgId;
                    setFacilityInfo(dataFacilityId);
                    cookies.set('facilityId', dataFacilityId, {path: '/'});
                    navigate(`/facility/${dataFacilityId}`,{state:{alertMessage:'Successfully Logged In!'}});
                } else {
                    setAlert(
                        'We could not validate your information. Please try again or contact support for assistance.',
                        'danger'
                    );
                }
            }
        } catch (e) {
            console.error(e);
        }
    }
    const RequiredStar = () => {
        const requiredColor = {color:'red'};
        return <span style={requiredColor}>*</span>
    }

    useEffect(() => {
        return () => {
            removeAlert();
        }
    },[])
    return <div>
            <LandingHeader title={'Sign in to the Patient Service Portal'}/>
            <div className="medicalContainer hc-center-md">
                <Alerts/>
                <RequiredStar/> <b>Required</b>
                <p>Sign in below using the account number from your statement and the patient's date of birth.</p>
                <Form className={'medicalForm'} onSubmit={handleSubmit}>
                    <Form.Group className={'signInFormGroup'}>
                        <Form.Label className={'signInFormGroupLabel'} htmlFor="accountNumber">Account Number <span>*</span></Form.Label>
                        <Form.Control type="text" name={'accountNumber'}/>
                        <Form.Text className={'text-muted'}>Please enter all digits of the account number. Example: 0012345678</Form.Text>
                    </Form.Group>
                    <div className="signInFormGroup">
                        <label className={'signInFormGroupLabel'} htmlFor="dob">Patient Date of Birth <span>*</span></label>
                        <div className={'dateDropdowns'}>
                            <div className="select-search">
                                <SelectSearch
                                    filterOptions={fuzzySearch}
                                    options={months()}
                                    value={selectedMonth}
                                    onChange={setSelectedMonth}
                                    name="month"
                                    placeholder="Month"
                                    search
                                />
                            </div>
                            <div className="select-search">
                                <SelectSearch
                                    filterOptions={fuzzySearch}
                                    options={days()}
                                    value={selectedDay}
                                    onChange={setSelectedDay}
                                    name="day"
                                    placeholder="Day"
                                    search
                                />
                            </div>
                            <div className="select-search">
                                <SelectSearch
                                    filterOptions={fuzzySearch}
                                    options={years()}
                                    value={selectedYear}
                                    onChange={setSelectedYear}
                                    name="year"
                                    placeholder="Year"
                                    search
                                />
                            </div>
                        </div>
                    </div>
                    <Form.Group className={'signInFormGroup'}>
                        <Form.Label className={'signInFormGroupLabel'} htmlFor={'name'}>Contact Name</Form.Label>
                        <Form.Control type={'text'} name={'name'}/>
                    </Form.Group>
                    <Form.Group className={'signInFormGroup'}>
                        <Form.Label className={'signInFormGroupLabel'} htmlFor={'phoneNumber'}>Contact Phone Number</Form.Label>
                        <Form.Control type={'text'} name={'phoneNumber'}/>
                    </Form.Group>
                    <input className="medicalBtn medicalBtn-primary" type="submit" value={'Submit'}/>
                </Form>
            </div>
        </div>
};

export default SignIn;