import {REMOVE_ALERT, SET_ALERT} from './alertTypes';



export default (state,action) => {
    switch (action.type) {
        case SET_ALERT:
            return {
                ...state,
                alert:action.payload,
                show:true
            }
        case REMOVE_ALERT:
            return {
                ...state,
                alert: {
                    msg:'',
                    type:'',
                },
                show: false
            }
        default:
            return state;
    }
}