import React from 'react';
import '../../css/demo/demo.scss'
import {useApi} from "../../hooks/useApi";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import Cookies from "universal-cookie/lib";
import {DEMO_ACCOUNTS, DEMO_CLIENT} from "../../utils/params";

const DemoLogin = () => {

    const {api} = useApi();
    const navigate = useNavigate();
    const cookies = new Cookies();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const login = (data) => {

        let loginData = {
            accountNumber: DEMO_ACCOUNTS,
            client: DEMO_CLIENT,
            username: data?.username,
            password: data?.password
        }

        api({route: 'demo/validateDemoUser', data: loginData, successMsg: 'Form submitted!', errorMsg: 'Error submitting form'})
            .then(
                (res) => {
                    if (res.data.status === "success") {
                        cookies.set("demoToken", true);
                        navigate("/estatements", { replace: true});
                    } else {
                        cookies.set("demoToken", false);
                    }
                }
            ).catch((error) => {
            console.log("Demo Login Error: ", error)
        });
    }

    return (
        <div id="loginContainer">
            <h2>
                Sign In to View Your Accounts
            </h2>
            <form onSubmit={handleSubmit(login)}>
                <label htmlFor="user">Email Address:</label>
                <input type="text" id="user" {...(register("username", {
                    required: {value: true, message: 'Username required!'}
                }))} />
                <label htmlFor="pass">Password:</label>
                <input type="password" id="pass" {...(register("password", {
                    required: {value: true, message: 'Password required!'}
                }))} />
                <input type="submit" value="Sign In" {...(register("login"))}  />
            </form>
        </div>
    )

}

export default DemoLogin;