import React, {useContext} from 'react';
import '../../css/demo/demo.scss'
import {useApi} from "../../hooks/useApi";
import {DEMO_ACCOUNTS, DEMO_CLIENT} from "../../utils/params";
import {useErrorHandler} from "react-error-boundary";
import {useNavigate} from "react-router-dom";
import {useDemo} from "../../context/demo/DemoProvider";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const DemoHeader = () => {
    const {ssoApi, api} = useApi();
    const handleError = useErrorHandler();
    const navigate = useNavigate();
    const {logout,cookies} = useDemo();
    const ssoData = {
        accountNumber: DEMO_ACCOUNTS,
        client: DEMO_CLIENT
    };

    const resetDemo = () => {
        try {
            let modalBody = "You are about to reset the demo accounts.";

            if(window.confirm(modalBody)) {
                redirect();
            }
        } catch (e) {
            handleError(e);
        }
    }

    const redirect = () => {
        if (cookies.get("demoToken") === "true") {
            api({
                route: 'demo/resetDemo',
                data: ssoData,
                successMsg: 'Reset submitted!',
                errorMsg: 'Error submitting reset'
            })
                .then(
                    (res) => {
                        if (res.data.status !== "success")
                            handleError('Invalid response from api: ' + JSON.stringify(res));
                        else {
                            logout();
                            navigate("/demo");
                        }
                    }
                ).catch((error) => {
                handleError(error)
            });
        }
    }

    const toggleMobileMenu = () => {
        let x = document.getElementById("navMenu");
        x.classList.toggle("mobileHide");
    }


    return (
        <header className="header-menu">
            <section id="headerContent">
                <div id="headerLogo">
                    <a href="/demo"><img
                        src='./../../assets/images/demo/HC3_Connect_600px_white.png'
                        alt="logo"/></a>
                </div>
                <div onClick={toggleMobileMenu}><FontAwesomeIcon className={'demoHamburger'} size={'2x'} icon={['fa','bars']} color={"#ffffff"}/></div>
                <nav id="navMenu" className="mobileHide">
                    <ul>
                        <li>
                            <a href="/demo">Home</a>
                        </li>
                        <li>
                            <a href="/estatements">E-Statements</a>
                        </li>
                        <li>
                            <a href="#">Contact</a>
                        </li>
                        {cookies.get("demoToken") &&
                        <li>
                            <div id="resetDemo">
                                <button type="button" onClick={resetDemo} className='btn btn-secondary'>Reset Demo</button>
                            </div>
                        </li>
                        }
                    </ul>
                </nav>
            </section>
            <section>
                <div className="accentLine"></div>
            </section>

        </header>
    );
}

export default DemoHeader;
