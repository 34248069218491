import React, {useState,useReducer} from 'react';
import SettingsContext from './settingsContext';
import SettingsReducer from './settingsReducer';
import {useApi} from "../../hooks/useApi";

const SettingsState = props => {

    const initialState = {
        settings :{}
    }

    const {api,medicalApi} = useApi();
    const [state,dispatch] = useReducer(SettingsReducer,initialState);

    const getSettings = async () =>
    {
        let config = {
            loader: {full_screen: true},
            blackout: true
        }
        const res = await api({route: 'settings/get', data: {}, headers: {}, config});
        let settings = res.data.settings;
        console.log("Settings:", {settings,count:Object.keys(settings).length});
        dispatch({type: 'set', payload: settings});
    }

    const getMedicalSettings = async () => {
        const res = await medicalApi({route:'medical/settings'})
        const settings = res.data.settings;
        // console.log("Medical Settings",{settings,count:Object.keys(settings).length});
        dispatch({type: 'set', payload: settings});
    }

    return (
        <SettingsContext.Provider value={{
            settings:state.settings,
            getSettings,
            getMedicalSettings
        }}>
            {props.children}
        </SettingsContext.Provider>
    )

}

export default SettingsState;