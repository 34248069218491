import React, {useContext, useState} from 'react';
import ModalContext from "./context/modal/modalContext";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import SampleTableModal from "./modals/SampleTableModal";

const ModalWindow = () => {
    const modalContext = useContext(ModalContext);
    const {show, content, removeModal} = modalContext;


    const ModalFormLayout = () => {

        let modalBody;

        if (content.api === false && content.body !== '') {
            modalBody = content.body;
        } else if (content.api !== false) {
            //Call modal content component based on template
            switch (content.template) {
                case 'SampleTableModal':
                    modalBody = <SampleTableModal content={content}/>;
                    break;
                default:
                    modalBody = "An error has occurred";
            }
        } else {
            modalBody = "An error has occurred";
        }

        const ButtonCloseMethod = () => {
            removeModal();
            if (typeof content.config.btnClose.func == 'function') {
                content.config.btnClose.func();
            }
        };

        const ButtonConfirmMethod = () => {
            removeModal();
            if (typeof content.config.btnConfirm.func == 'function') {
                content.config.btnConfirm.func();
            }
        }

        //let buttonCloseText = typeof content.config.btnClose.text !== 'undefined' ? content.config.btnClose.text : 'Close';
        let buttonCloseText = 'Close';
        let buttonConfirmText = 'Confirm';

        if(typeof content.config.btnClose !== 'undefined' && typeof content.config.btnClose.text !== 'undefined') {
            buttonCloseText = content.config.btnClose.text;
        }
        if(typeof content.config.btnConfirm !== 'undefined' && typeof content.config.btnConfirm.text !== 'undefined') {
            buttonConfirmText = content.config.btnConfirm.text;
        }

        return (
            <Modal
                show={show}
                size={content.config.size}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName={content.dialogClassName}
                backdrop={content.config.static_bg}
                animation={content.config.animation}
                scrollable={content.config.scrollable}
                onExited={content.config.onExit}
                onHide={ButtonCloseMethod}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        { content.title }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    { modalBody }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={ButtonCloseMethod}>{buttonCloseText}</Button>
                    {content.config.btnConfirm !== undefined && Object.entries(content.config.btnConfirm).length > 0 &&
                        <Button variant="primary" onClick={ButtonConfirmMethod}>{buttonConfirmText}</Button>
                    }
                </Modal.Footer>
            </Modal>
        );
    };


    return (
        <ModalFormLayout />
    );
};




export default ModalWindow;