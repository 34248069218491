import "core-js";
import "regenerator-runtime/runtime";
import 'fast-text-encoding';
import React, { Suspense } from 'react';

import {render} from 'react-dom';
import './css/index.scss';
import App from './App';

import i18next from "i18next";
import {I18nextProvider} from "react-i18next";
import 'bootstrap/dist/css/bootstrap.min.css';

import {BrowserRouter as Router} from "react-router-dom";

render(
    <Router>
        <I18nextProvider i18n={i18next}>
            <Suspense fallback={<div> </div>}>
                <App/>
            </Suspense>
        </I18nextProvider>
    </Router>, document.getElementById("root")
);