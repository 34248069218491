import React, {useContext, useEffect, useState} from 'react';
import Cookies from "universal-cookie/lib";
import {useIdleTimer} from "react-idle-timer";
import ModalContext from "../context/modal/modalContext";
import {Navigate} from "react-router-dom";
import useUser from "../hooks/useUser";
import {useMedical} from "../context/medical/MedicalProvider";
const cookies = new Cookies();

const Timer = ({medicalTimer = false}) => {

    let loggedIn = !!cookies.get('awst');
    const {logout, user} = useUser();

    let medical;
    let medicalAuth;
    if (medicalTimer) {
        medical = useMedical();
        medicalAuth = cookies.get('medicalToken');
    }

    const modalContext = useContext(ModalContext);
    const {setModal, removeModal} = modalContext;

    const [seconds, setSeconds] = useState(0);

    // State management to check if timeout timer is active
    const [timerIsActive, setTimerIsActive] = useState(false);
    const [doLogout, setDoLogout] = useState(false);

    // 15 min
    let timeout = 15;
    if (user?.adminLogin || user?.userType === 'admin') {
        // if admin session, change to 30 min
        timeout = 30;
    }


    useEffect(() => {
        let interval = null;
        if (timerIsActive && seconds >= 1) {
            // Setup modal. Ex: Title, Body, Custom Class, Template, API, Config Options
            let modalBody = 'You are being timed-out due to inactivity. Please choose to stay signed in or to logoff. \n' +
                'Otherwise, you will be logged off automatically.';

            setModal("Session Timeout", modalBody, '', 'timeoutDialog', false, {
                static_bg: false,
                animation: false,
                size: 'md',
                scrollable: false,
                onExit: '',
                btnClose: {
                    func: function() { setTimerIsActive(false); },
                    text: 'Continue ('+String(seconds)+')',
                },
            });

            // Todo: Create a centralized logout method to call
            if (seconds <= 1) {
                setDoLogout(true)
                removeModal();
                return( <Navigate to="/login"/> );
            }
            interval = setInterval(() => {
                setSeconds(seconds => seconds - 1);
            }, 1000);
        } else if (!timerIsActive) {
            // If timerIsActive === false, clear the timer because the user is active.
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [timerIsActive, seconds]);

    // Make sure the logout method only gets called once
    useEffect(() => {
        if (doLogout === true) {
            if (medicalTimer) medical.logout();
            else logout();
            setDoLogout(false);
        }
    },[doLogout])

    const HandleOnIdle = event => {
        // Required for the timeout library
        // Use this to detect when the user becomes idle

        if (loggedIn || medicalAuth) {
            //console.log('user is idle', event);
            //console.log('last active', getLastActiveTime());

            // Start timer to log the user out
            setTimerIsActive(true);
            setSeconds(30);

        }
    };

    const handleOnActive = event => {
        // Required for the timeout library
        // Use this if we ever need to detect that the user is active
    };

    const handleOnAction = (e) => {
        // Required for the timeout library
        // Use this if we ever need to detect an action
    };

    const { getRemainingTime, getLastActiveTime } = useIdleTimer({
        timeout: 1000 * 60 * timeout,
        onIdle: HandleOnIdle,
        onActive: handleOnActive,
        onAction: handleOnAction,
        debounce: 500
    });

    return (
        <>
        </>
    );
};

export default Timer;