import React from 'react';
import {ErrorBoundary} from "react-error-boundary";
import {generateStringId} from "./utils/utils";
import ErrorFallback from "./components/ErrorFallback";
import {Route, Routes, Navigate} from "react-router-dom";

import DemoIndex from "./components/demo/DemoIndex";
import DemoFooter from "./components/demo/DemoFooter";
import DemoHeader from "./components/demo/DemoHeader";
import Estatements from "./components/demo/Estatements";

import {library as icon_library} from '@fortawesome/fontawesome-svg-core';
import {faMoneyCheck} from "@fortawesome/free-solid-svg-icons/faMoneyCheck";
import {faHandHoldingUsd} from "@fortawesome/free-solid-svg-icons/faHandHoldingUsd";
import {faPiggyBank} from "@fortawesome/free-solid-svg-icons/faPiggyBank";
import {faAngleDoubleRight} from "@fortawesome/free-solid-svg-icons/faAngleDoubleRight";
import {faAngleDoubleLeft} from "@fortawesome/free-solid-svg-icons/faAngleDoubleLeft";
import {faAngleRight} from "@fortawesome/free-solid-svg-icons/faAngleRight";
import {faAngleLeft} from "@fortawesome/free-solid-svg-icons/faAngleLeft";
import {faSearch} from "@fortawesome/free-solid-svg-icons/faSearch";
import {faCaretSquareDown} from "@fortawesome/free-solid-svg-icons/faCaretSquareDown";
import {faFileAlt} from "@fortawesome/free-solid-svg-icons/faFileAlt";
import {faDownload} from "@fortawesome/free-solid-svg-icons/faDownload";
import DemoAppProvider from "./context/DemoAppProvider";

icon_library.add(
    faMoneyCheck, faHandHoldingUsd, faPiggyBank, faAngleDoubleRight, faAngleDoubleLeft, faAngleRight, faAngleLeft,
    faSearch, faCaretSquareDown, faFileAlt, faDownload
);


const DemoApp = () => {

    return (
        <DemoAppProvider>

            <div className="App demoBody">

                <ErrorBoundary key={generateStringId()} fallbackRender={props => <ErrorFallback {...props}/>
                }>
                    <DemoHeader/>
                        <Routes>
                            <Route path="/" element={<Navigate replace to='/demo'/>}/>
                            <Route path="/demo" element={<DemoIndex/>}/>
                            <Route path="/estatements" element={<Estatements/>}/>
                        </Routes>
                    <DemoFooter/>
                </ErrorBoundary>
            </div>

        </DemoAppProvider>
    );
};

export default DemoApp;