import React, {useState} from 'react';

const useDateDropdowns = () => {


    const [selectedMonth,setSelectedMonth] = useState('');
    const [selectedDay,setSelectedDay] = useState('');
    const [selectedYear,setSelectedYear] = useState('');

    const months = () => {
        let res = [];
        for (let i = 1; i <= 12; i++) {
            res.push({
                name:new Date(2022,i-1).toLocaleString('en-us', {month: 'short'}),
                value:i
            });
        }
        return res;
    }

    const days = () => {
        let days = [];
        for (let i = 1; i < 32; i++) {
            days.push({
                name:`${i}`,
                value:i
            })
        }
        return days;
    }
    const years = () => {
        const currentYear = new Date().getFullYear();
        let years = [];
        for (let i = 0; i < 130; i++) {
            const y = currentYear - i
            years.push({name: `${y}`,value:y });
        }
        return years;
    }
    const getDateInfo = () => {
        return {
            selectedDay,selectedMonth,selectedYear
        }
    }

    return {
        selectedDay,selectedMonth,selectedYear,
        setSelectedYear,setSelectedMonth,setSelectedDay,
        days,months,years,getDateInfo
    }
};

export default useDateDropdowns;