import React, {useEffect} from 'react';
import Alerts from "../Alerts";
import Timer from "./Timer";
import useAlert from "../hooks/useAlert";
import ModalWindow from "../ModalWindow";
import useUser from "../hooks/useUser";
import Cookies from "universal-cookie/lib";

const HCPage = ({component:Component}) => {


      const {removeAlert,setAlert} = useAlert();
      const {user} = useUser();
      const cookies = new Cookies();
      useEffect(()=> {

          return () => {
              removeAlert();
          }
      },[])

    // for alerting the user how many days until their password expires
    useEffect(() => {
        if (user) {
            const daysTilExpire = user['days_before_pw_expire'];
            const pwAlertDate = cookies.get('pwAlertDate') ?? '';

            const todaysDate = new Date();

            const day = todaysDate.getDate();
            const month = todaysDate.getMonth()+1;
            const year = todaysDate.getFullYear();

            const strDate = month+'/'+day+'/'+year;


            if (daysTilExpire !== undefined) {
                //setting the alert date cookies to today's date so that the alert will only show once a day
                if (strDate !== pwAlertDate) {
                    setAlert(`Your password will expire in ${daysTilExpire} ${daysTilExpire === 1 ? 'day' :'days'}` , 'danger');
                    cookies.set('pwAlertDate',strDate,{path:'/'})
                }
            }
        }
    },[user]);

    return (
        <>
            <ModalWindow/>
            <Alerts/>
            <Timer/>
            <Component/>
        </>
    );
};


export default HCPage;