import React from 'react';
import {useTranslation} from "react-i18next";
import useSettings from "./useSettings";
import useUser from "./useUser";
import Cookies from "universal-cookie/lib";

const useHcNavigation = () => {
    const {t,i18n} = useTranslation('common');

    const {settings} = useSettings();

    const {hasPermission,logout,user} = useUser();

    // Load features from cookie
    const cookies = new Cookies();
    let featureString = cookies.get('clientFeatures').toString();
    const features = featureString.split(',');

    return {t,settings,hasPermission,logout,features,user}
};

export default useHcNavigation;