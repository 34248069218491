import React from 'react';
import SettingsState from "./settings/SettingsState";
import AlertState from "./alert/AlertState";
import ModalState from "./modal/modalState";
import {DemoProvider} from "./demo/DemoProvider";

const DemoAppProvider = ({children}) => {
    return (
        <SettingsState>
            <DemoProvider>
                <ModalState>
                    <AlertState>
                        {children}
                    </AlertState>
                </ModalState>
            </DemoProvider>
        </SettingsState>
    );
};

export default DemoAppProvider;