import {REMOVE_MODAL, SET_MODAL} from './modalTypes';



export default (state,action) => {
    switch (action.type) {
        case SET_MODAL:
            return {
                ...state,
                content:action.payload,
                show:true
            }
        case REMOVE_MODAL:
            return {
                ...state,
                content: {
                    title:'',
                    body:'',
                    dialogClassName : '',
                    template: '',
                    api: false,
                    config: {
                        static_bg: false,
                        animation: true,
                        size: 'lg',
                        scrollable: false,
                        onExit: '',
                        btnClose: {},
                        btnConfirm: {}
                    },
                },
                show: false
            }
        default:
            return state;
    }
}

