import React from 'react';
import '../../css/medical/css/medicalStyle.css'
const LandingHeader = ({title = 'Welcome to the Patient Service Portal'}) => {
    return (
        <div className="landingHeader fullMenuHeader">
            <h2>{title}</h2>
        </div>
    );
};

export default LandingHeader;