import React, {createContext,useReducer} from 'react';
import modalReducer from "./modalReducer";
import {SET_MODAL,REMOVE_MODAL} from "./modalTypes";
import ModalContext from './modalContext';
const ModalState = props => {

    const initialState = {
        content: {
            title:'test',
            body:'',
            dialogClassName: '',
            template: '',
            api: false,
            config: {
                static_bg: false,
                animation: true,
                size: 'lg',
                scrollable: false,
                onExit: '',
                btnClose: '',
            }
        },
        show: false,
    };
    const [state,dispatch] = useReducer(modalReducer,initialState);


    const setModal = (title,body,dialogClassName ='', template ='', api = false,
                      config = {
                          static_bg: false,
                          animation: true,
                          size: 'lg',
                          scrollable: false,
                          onExit: '',
                          btnClose: '',
                      }
    ) => {

        dispatch({
            type:SET_MODAL,
            payload:{title,body,dialogClassName, template, api, config}
        })

    };

    const removeModal = () => dispatch({type:REMOVE_MODAL})

    return (
        <ModalContext.Provider value={{
            content:state.content,
            show:state.show,
            setModal,
            removeModal
        }}>
            {props.children}
        </ModalContext.Provider>
    )
};

export default ModalState;