export default  {
    DASHBOARD: '/dashboard',

    /* Staff */
    STAFF:'/staff',
    ADD_STAFF:'/staff/add',
    EDIT_STAFF:'/staff/edit',
    EDIT_ADMIN_CLIENTS:'/staff/edit/adminClients',
    STAFF_LOGIN_RECORDS:'/staff/loginrecords',
    STAFF_AUDIT:'/staff/audit',

    /* Usergroups */
    USERGROUPS:'/usergroups',
    EDIT_USERGROUPS:'/usergroups/edit',
    ADD_USERGROUPS: '/usergroups/add',
    EDIT_USERGROUP_PERMISSIONS:'/usergroups/permissions',
    USERGROUP_MEMBERS:'/usergroups/members',
    USERGROUP_AUDIT:'/usergroups/audit',

    /* Clients */
    CLIENTS:'/clients',
    ADD_CLIENTS:'/clients/add',
    EDIT_CLIENTS:'/clients/edit',
    CLIENT_WHITELISTS:'/clients/whitelists',
    ADD_CLIENT_WHITELISTS:'/clients/whitelists/add',
    EDIT_CLIENT_WHITELISTS:'/clients/whitelists/edit',
    CLIENT_FEATURES:'clients/features',

    /* Orgs */
    ORGS:'/orgs',
    ADD_ORGS:'/orgs/add',
    EDIT_ORGS:'/orgs/edit',
    ORGS_AUDIT:'/orgs/audit',

    /* Features */
    FEATURES:'/features',
    ADD_FEATURES:'/features/add',
    EDIT_FEATURES:'/features/edit',


    /* Permissions */
    PERMISSIONS:'/permissions',
    ADD_PERMISSIONS:'/permissions/add',
    EDIT_PERMISSIONS:'/permissions/edit',

    /* Settings */
    SETTINGS:'/settings',
    ADD_SETTINGS:'/settings/add',
    VIEW_VALUES:'/settings/view',


    /* SSO */
    SSO_TEST:'/ssotest',

    /* Reports */
    REPORTS:'/reports',

    /* Assets */
    ASSETS:'/assets',

    /*Profile*/
    PROFILE:'/profile',


    /* Documents */
    DOCUMENTS:'/documents',
    VIEW_DOCUMENTS:'/documents/view',
    DOCUMENT_INFO:'/documents/info',
    MY_MAIL_DOCS:'/documents/mailtracking',
    MY_MAIL_DOCS_INFO:'/documents/mailtracking/info',
    DOCUMENTS_LIST:'/documentslist',
    DOCUMENTS_LIST_MAIL_TRACKING:'/documentslist/mailtracking',
    DOCUMENTS_LIST_MAIL_TRACKING_INFO:'/documentslist/mailtracking/info',

    /* My Mail*/
    MY_MAIL_MAIN:'/mailtracking',
    MAIL_PIECES:'/mailtracking/mailpieces',
    MY_MAIL_INFO_2:'/mailtracking/mailpieces/info',

    /*Transactions*/
    TRANSACTIONS:'/transactions',

    /* Jobs */
    JOBS:'/jobs',
    JOBREPORTS:'/jobreports',
    JOBS_AUDIT:'/jobs/audit',

    /* Accounts */
    ACCOUNTS:'/accounts',
    ACCOUNT_INFO:'/accounts/info',
    ACCOUNTS_DELIVERY:'/accounts/delivery',
    ACCOUNTS_AUDIT:'/accounts/audit',

    /* Customers */

    CUSTOMERS:'/customers',
    EDIT_CUSTOMERS:'/customers/edit',
    CUSTOMER_LOGIN_RECORDS:'/customers/loginrecords',
    CUSTOMER_AUDIT:'/customers/audit',


    MESSAGING:'/messaging',

    /* Emails */
    EMAIL_LISTS:'/emaillists',
    EMAIL_BLASTS:'/emailblasts',

    /* Proxy */

    PROXY_USERS:'/proxyusers',
    ADD_PROXY:'/accounts/viewproxy',
    PROXY_LOGIN_RECORDS:'/proxyusers/loginrecords',
    PROXY_AUDIT:'/proxyusers/audit'


}