import Cookies from "universal-cookie/lib";
const cookies = new Cookies();

export default (state,action) => {
    switch (action.type) {
        case "SET_USER":
            return {
                ...state,
                user:action.payload
            }
        case "UPDATE_USER":
            cookies.set('user',action.payload);
            return {
                ...state,
                user:action.payload
            }
        case "LOGOUT":
            cookies.remove('awst',{path:'/'});
            cookies.remove('awsid',{path:'/'});
            cookies.remove('user',{path:'/'});
            cookies.remove('preferences',{path:'/'});
            cookies.remove('poolID',{path:'/'});
            cookies.remove('locale',{path:'/'});
            cookies.remove('clientFeatures',{path:'/'});
            cookies.remove('clientName',{path:'/'});
            cookies.remove('clientType',{path:'/'});
            cookies.remove('interfaceType',{path:'/'});
            cookies.remove('appId',{path:'/'});

            return {
                ...state,
                user:null
            }
        default:
            return state;
    }
}