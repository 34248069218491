import React from 'react';
import UserState from "./user/UserState";
import SettingsState from "./settings/SettingsState";
import {MenuProvider} from "./MenuContext";
import ModalState from "./modal/modalState";
import AlertState from "./alert/AlertState";
import {SubClientProvider} from "./SubClientContext";
import Cookies from "universal-cookie";

const cookies = new Cookies();

// Lazy Load Themes
const LightTheme = React.lazy(() => import("../themes/Light").then((module) => ({default: module.default})));
const DarkTheme = React.lazy(() => import("../themes/Dark").then((module) => ({default: module.default})));

const ThemeSelector = ({children}) => {
    const prefCookie = cookies.get('preferences') || {};
    const user_theme = prefCookie.theme || 'light';

    return (
        <>
            <React.Suspense fallback={<></>}>
                {(user_theme === 'light') && <LightTheme/>}
                {(user_theme === 'dark') && <DarkTheme/>}
            </React.Suspense>
            {children}
        </>
    )
}

const AppProvider = ({children}) => {
    return (
        <UserState>
            <SettingsState>
                <SubClientProvider>
                    <MenuProvider>
                        <ModalState>
                            <AlertState>
                                <ThemeSelector>
                                    {children}
                                </ThemeSelector>
                            </AlertState>
                        </ModalState>
                    </MenuProvider>
                </SubClientProvider>
            </SettingsState>
        </UserState>
    );
};

export default AppProvider;