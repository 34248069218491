import React, {useEffect} from 'react';
import {useParams, useNavigate, Navigate} from "react-router-dom";
import {useMedical} from "../../context/medical/MedicalProvider";
import Cookies from "universal-cookie/lib";

const RedirectToFacility = () => {

    const cookies = new Cookies();
    const {facilityId} = useParams();
    const navigate = useNavigate();
    const redirectRoute = `/facility/${facilityId}`;
    const {logout} = useMedical();
    cookies.remove('medicalToken',{path:'/'});

    const resetCookies = () => {
        cookies.remove('groupName',{path:'/'});
        cookies.remove('displayName',{path:'/'});
        cookies.remove('facilityId',{path:'/'});
        cookies.remove('medicalToken',{path:'/'});

    }
    useEffect(()=>{
        resetCookies();
        logout();
        navigate(redirectRoute)
    },[])
    return <></>
};

export default RedirectToFacility;