
/*1000-user errors*/

/*2000-interface api*/
export const CALL_API = 2001;

/*3000-api errors(mea etc)*/

/* 4000-frontend errors */
export const INTERFACE_ERROR = 4000;
export const DATATABLE= 4001;
export const DELIVERY_PREFERENCE_ERROR = 4002;
export const ROOT_APP = 4003;
export const TRANSACTION = 4004;
export const DOCUMENT_MODAL = 4005;
export const OPEN_FORM = 4006;
export const FORM_CONTEXT = 4007;
export const USER_GROUP_PERMISSION = 4008;
export const PAGE_LOAD = 4009;
export const LOGIN_RECORDS = 4010;


/*5000-system*/

/*6000 SSO*/
export const SSO_ERROR = 6000
export const SSO_CONTEXT = 6001

export const SSO_TEST_CONTEXT = 6100

