import React, {lazy} from 'react';
import '../src/css/index.scss'
import './css/medical/css/medicalStyle.css'
import './css/App.scss';
import {ErrorBoundary} from "react-error-boundary";
import {generateStringId} from "./utils/utils";
import ErrorFallback from "./components/ErrorFallback";
import {Route, Routes, Navigate} from "react-router-dom";
import MedicalLandingPage from "./components/medical/MedicalLandingPage";

// Import Individual FontAwesome Icons
import {library as icon_library} from '@fortawesome/fontawesome-svg-core';
import {faHome} from '@fortawesome/free-solid-svg-icons/faHome';
import {faCreditCard} from "@fortawesome/free-solid-svg-icons/faCreditCard";
import {faGlobe} from "@fortawesome/free-solid-svg-icons/faGlobe";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons/faInfoCircle";
import {faHeadphones} from "@fortawesome/free-solid-svg-icons/faHeadphones";
import {faComments} from "@fortawesome/free-solid-svg-icons/faComments";
import {faReceipt} from "@fortawesome/free-solid-svg-icons/faReceipt";
import {faSignOutAlt} from "@fortawesome/free-solid-svg-icons/faSignOutAlt";
import {faPowerOff} from "@fortawesome/free-solid-svg-icons/faPowerOff";
import {faBars} from "@fortawesome/free-solid-svg-icons/faBars";
import {faTimes, faUser} from "@fortawesome/pro-solid-svg-icons";
import {faArrowLeft} from "@fortawesome/pro-solid-svg-icons";
import {faArrowRight} from "@fortawesome/pro-solid-svg-icons";
import {faDatabase} from "@fortawesome/pro-solid-svg-icons";
import {faPrint} from "@fortawesome/pro-solid-svg-icons";
import {faSearchMinus} from "@fortawesome/pro-solid-svg-icons/faSearchMinus";
import {faSearchPlus} from "@fortawesome/pro-solid-svg-icons/faSearchPlus";
import {faSearch} from "@fortawesome/pro-solid-svg-icons/faSearch";
import {faCaretSquareDown} from "@fortawesome/pro-solid-svg-icons";
import {faCaretSquareUp} from "@fortawesome/pro-solid-svg-icons";
import MedicalNavigation from "./components/medical/MedicalNavigation";
import SignIn from "./components/medical/SignIn";
import MedicalAppProvider from "./context/MedicalAppProvider";
import MedicalAuthRoute from "./components/MedicalAuthRoute";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons/faChevronDown";
import {faChevronUp} from "@fortawesome/free-solid-svg-icons/faChevronUp";
import MedicalUnAuthRoute from "./components/medical/MedicalUnAuthRoute";
import RedirectToFacility from "./components/medical/RedirectToFacility";
// const RedirectToFacility = lazy(() => import('./components/medical/RedirectToFacility').then((module) => ({ default: module.default})));
const UpdateInsurance = lazy(() => import('./components/medical/UpdateInsurance').then((module) => ({ default: module.default})));
const ViewStatement = lazy(() => import('./components/medical/ViewStatement').then((module) => ({ default: module.default})));
const HistoricalStatements = lazy(() => import('./components/medical/HistoricalStatements').then((module) => ({ default: module.default})));
const FacilityLanding = lazy(() => import('./components/medical/FacilityLanding').then((module) => ({ default: module.default})));
const ContactUs = lazy(() => import('./components/medical/ContactUs').then((module) => ({ default: module.default})));
const FAQs = lazy(() => import('./components/medical/FAQs').then((module) => ({ default: module.default})));
icon_library.add(
    faHome, faCreditCard, faGlobe,
    faInfoCircle, faHeadphones, faComments,
    faReceipt, faSignOutAlt, faPowerOff,
    faBars,faChevronDown,faChevronUp,
    faUser,faArrowLeft,faArrowRight,
    faDatabase,faPrint,
    faBars,faChevronDown,faChevronUp,
    faSearchMinus,faSearch,faSearchPlus,
    faCaretSquareDown,faCaretSquareUp,faTimes
);

const MedicalApp = () => {

    const privacyPolicyOpen = () => {
        const newWindow = window.open('http://www.r1rcm.com/privacy','privacypolicy','resizable=yes,scrollbars=yes,menubar=yes,width=600,height=600');
        newWindow.focus();
        return false;
    }
    return (
        <MedicalAppProvider>
            <div className="MedicalApp medicalBody">
                <MedicalNavigation/>
                <ErrorBoundary key={generateStringId()} fallbackRender={props => <ErrorFallback {...props}/>
                }>
                    <Routes>
                        <Route path="/" element={<Navigate replace to='/medical_landing'/>}/>
                        <Route path="/medical_landing" element={<MedicalLandingPage/>}/>
                        <Route path="/facility" element={<Navigate replace to="/"/>}/>
                        <Route path="/log-in" element={<SignIn/>}/>
                        <Route path="/faqs" element={<MedicalUnAuthRoute component={FAQs} /> }/>
                        <Route path="/ask" element={<MedicalUnAuthRoute component={ContactUs} />}/>
                        <Route path={"/insuranceinformation"} element={<MedicalAuthRoute component={UpdateInsurance}/>}/>
                        <Route path={"/viewstatement"} element={<MedicalAuthRoute component={ViewStatement}/>}/>
                        <Route path={"/my-documents"} element={<MedicalAuthRoute component={HistoricalStatements}/>}/>
                        <Route path={"/my-documents/view"} element={<MedicalAuthRoute component={ViewStatement}/>}/>
                        <Route path={"/:facilityId"} element={<RedirectToFacility/>}/>
                        <Route path="/facility/:facilityId" element={<MedicalAuthRoute component={FacilityLanding}/>}/>
                    </Routes>
                </ErrorBoundary>
            </div>
            <footer className="footer">
                Copyright &copy; {new Date().getFullYear()} Statement.Services |
                <a id={'policyLink'} href="http://www.r1rcm.com/privacy" target="_blank"
                   onClick={privacyPolicyOpen}>
                    Privacy Policy
                </a>
            </footer>
        </MedicalAppProvider>
    );
};

export default MedicalApp;