import React, {useContext, useState} from 'react';
import {createContext} from "react";
import {reactData} from "../../utils/reactData";

const SSOContext = createContext();
SSOContext.displayName = 'SSOContext';


const useSSO = () => {
    const context = useContext(SSOContext);
    if (!context) throw new Error('useSSO must be used within an SSO Provider');
    return context;
}

const SsoProvider = props => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [ssoAccountData, setSSOAccountData] = useState({});
    const [ssoRegistrationOptions, setSSORegistrationOptions] = useState({});
    const [currentAccount, setCurrentAccount] = useState("all");
    const [isDigital,setIsDigital] = useState("true");
    const [currentAccountNumber, setCurrentAccountNumber] = useState("all");
    const [currentExtAccountId, setCurrentExtAccountId] = useState("all");
    const [defaultAccount, setDefaultAccount] = useState({});
    const [tabIndex, setTabIndex] = useState(0);
    const [ssoPage, setSSOPage] = useState('');
    const [termsChecked, setTermsChecked] = useState(false);
    const [esignChecked, setEsignChecked] = useState(false);
    const [needsTerms, setNeedsTerms] = useState(false);
    const [needsEsign, setNeedsEsign] = useState(false);
    const [performCheck, setPerformCheck] = useState(true);
    const [forceInitialReg, setForceInitialReg] = useState(false);
    const [verifyTimerCount, setVerifyTimerCount] = useState(0)
    const [startVerifyTimerCount, setStartVerifyTimerCount] = useState(false)
    const [manualVerifyMessage, setManualVerifyMessage] = useState(false);
    const [isRegistered, setIsRegistered] = useState(false);
    const [docId, setDocId] = useState(null);
    const ssoId = reactData.ssoId;
    const value = {
        ssoId,
        isLoaded,setIsLoaded,
        ssoAccountData,setSSOAccountData,
        ssoRegistrationOptions,setSSORegistrationOptions,
        currentAccount,setCurrentAccount,
        isDigital,setIsDigital,
        defaultAccount,setDefaultAccount,
        tabIndex,setTabIndex,
        ssoPage,setSSOPage,termsChecked,setTermsChecked,
        esignChecked,setEsignChecked,currentAccountNumber,setCurrentAccountNumber,
        needsTerms,setNeedsTerms,needsEsign,setNeedsEsign,performCheck,setPerformCheck,
        currentExtAccountId,setCurrentExtAccountId,forceInitialReg,setForceInitialReg,
        verifyTimerCount, setVerifyTimerCount,startVerifyTimerCount, setStartVerifyTimerCount,
        manualVerifyMessage, setManualVerifyMessage, isRegistered, setIsRegistered,docId, setDocId
    }
    return <SSOContext.Provider value={value} {...props}/>
};

export  {useSSO,SsoProvider};