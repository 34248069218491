import React, {createContext, useContext, useState} from 'react';

import '../../css/medical/css/medicalStyle.css';
import '../../css/medical/css/utilities.css';
import Cookies from "universal-cookie/lib";
import {useApi} from "../../hooks/useApi";
import useSettings from "../../hooks/useSettings";
import {useNavigate, useParams} from "react-router-dom";

const MedicalContext = createContext();


const useMedical = () => {
    const context = useContext(MedicalContext);
    if (!context) throw new Error('useMedical must be used within a MedicalProvider');
    return context;
}

const MedicalProvider = props => {

    const [displayHeader,setDisplayHeader] = useState(true);
    const [user,setUser] = useState(null);
    const [displayName,setDisplayName] = useState('');
    const [groupName,setGroupName] = useState('');
    const [paymentUrl,setPaymentUrl] = useState('');
    const [facilityId,setFacilityId] = useState('');
    const [accountNumber,setAccountNumber] = useState('');
    const [latestDocId,setLatestDocId] = useState('');
    const [link,setLink] = useState('')
    const [loading,setLoading] = useState(true);
    const navigate = useNavigate();

    //chat state
    const [isChatOpen,setIsChatOpen] = useState(false);
    const [firstLoad, setFirstLoad] = useState(true);
    const [urlError,setUrlError] = useState(false);
    const [chatHeight,setChatHeight] = useState('50px');
    const [highlightClass,setHighlightClass] = useState('');

    const {settings} = useSettings();
    const {api} = useApi();
    const {avtex_chat_enable} = settings;
    const urlParams = useParams();
    const toggleChat = () => {
        const chatContainer = document.getElementById('chatContainer');
        let chatKeyFrames = [
            isChatOpen ?
                {height:'50px'}:
                {height:'600px'}
        ];
        let chatOptions = {
            duration:500,
            fill:'forwards'
        };
        setIsChatOpen(!isChatOpen);
        chatContainer.animate(chatKeyFrames,chatOptions)
    }

    // Call attention to open chat window
    const highlightChat = () => {
        const fl = setInterval(function() {
            setHighlightClass('chatHighlight');
            // setHighlightClass('');
            // document.getElementById('chatContainer').toggleClass('chatHighlight');
        }, 100);

        setTimeout(function() {
            clearInterval(fl);
            // document.getElementById('chatContainer').removeClass('chatHighlight');
            setHighlightClass('');
        }, 600);
    }



    const {medicalApi} = useApi();
    const {getMedicalSettings} = useSettings();
    const cookies = new Cookies();

    const setInfo = ({displayName,groupName,paymentUrl,facilityId}) => {
        setDisplayName(displayName);
        cookies.set('displayName', displayName, {path: '/'})

        setGroupName(groupName);
        cookies.set('groupName', groupName, {path: '/'})

        setPaymentUrl(paymentUrl);
        cookies.set('paymentUrl', paymentUrl, {path: '/'})

        setFacilityInfo(facilityId);
    }

    const setFacilityInfo = facilityId => {
        setFacilityId(facilityId);
        cookies.set('facilityId', facilityId, {path: '/'})
    }

    /**
     * returns the facilityId cookie value
     */
    const getFacilityInfo = () => {
        return cookies.get('facilityId');
    }

    const getInfo = () => {
        setDisplayName(cookies.get('displayName'));
        setGroupName(cookies.get('groupName'));
        setPaymentUrl(cookies.get('paymentUrl'));
        setFacilityId(cookies.get('facilityId'));
    }

    const validateFacility = async (facilityId) => {

        try {
            const {data} = await api({
                route: 'medical/validate',
                data: { facilityId },
            });
            // console.log("validateFacility Data",data)
            return data;
        }
        catch (e) {
            console.error(e)
        }
    }

    const handleValidatedUserData = data => {
        setUser(data);
        setAccountNumber(data.extAccountId);
        setLatestDocId(data.latestDocId);
        setLink(data.link);
        cookies.set('medicalToken',data.token,{path:'/'})
        getMedicalSettings();
        const cookieFacilityId = cookies.get('facilityId');
        const dataFacilityId = data.extOrgId;
        if (cookieFacilityId !== dataFacilityId) {
            const {displayName,paymentUrl,groupName} = data;
            setFacilityInfo(dataFacilityId)
            setInfo({displayName, groupName, paymentUrl, facilityId:dataFacilityId})
            navigate(`facility/${dataFacilityId}`);
        }
    }

    const getUser = async () => {

        setLoading(true);
            const token = cookies.get('medicalToken');
            if (token !== undefined) {
                const res = await medicalApi({
                    route:'medical/getuser',
                    data:{token}
                });
                if (res !== undefined && res.data !== undefined && res.data.validated ) {
                    // if facility id is empty/undefined, the user is logging in
                    if ((facilityId === '' || facilityId === undefined) ||
                        res.data.extOrgId === facilityId) {
                        handleValidatedUserData(res.data);
                    }
                    else {
                        if (res.data.extOrgId !== facilityId) {
                            logout();
                        }
                    }
                }
            }

        setLoading(false);
    }

    const logout = () => {
        cookies.remove('medicalToken',{path:'/'});
        setIsChatOpen(false);
        setUser(null);
    }

    const handleChat = () => {
        if (avtex_chat_enable) {
            if (isChatOpen) {
                highlightChat();
            } else {
                toggleChat();
            }
        }
    }


    const value = {
        user,setUser,
        displayHeader,setDisplayHeader,
        displayName, groupName,
        paymentUrl, facilityId,
        accountNumber,setAccountNumber,
        latestDocId,setLatestDocId,loading,
        getInfo,getUser,setInfo,logout,setFacilityInfo,getFacilityInfo,
        handleValidatedUserData,toggleChat,handleChat,
        highlightClass,setHighlightClass,
        chatHeight,setChatHeight,
        firstLoad,setFirstLoad,
        isChatOpen,setIsChatOpen,
        link,
        validateFacility
    }

    return <MedicalContext.Provider value={value} {...props}/>
};

export {MedicalProvider,useMedical}