import React from 'react';
import {NavLink} from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import useHcNavigation from "../hooks/useHcNavigation";

const AdminNavigation = (props) =>
{
    //todo implement settings context object for navigation page
    const {t,settings, hasPermission,logout,features} = useHcNavigation();
    const {clientShowOrgs} = settings;



    return (
        <nav>
            <ul>
                {
                    features.includes('dashboard') &&
                    hasPermission('dashboard_view') &&
                    <li>
                        <NavLink to="/dashboard" 
                                 className={({ isActive }) => "leftNavConLinks" + (isActive ? " active-nav" : "")}
                                 onClick={props.menuCallback}>
                            <FontAwesomeIcon icon={['fas', 'home']}/> <span className="navLabel">{t('main.link.dashboard')}</span>
                        </NavLink>
                    </li>
                }
                {
                    clientShowOrgs &&
                    features.includes('orgs') &&
                    hasPermission('orgs_view') &&
                    <li>
                        <NavLink to="/orgs" className={({ isActive }) => "leftNavConLinks" + (isActive ? " active-nav" : "")}
                                 onClick={props.menuCallback}>
                            <FontAwesomeIcon icon={['fas', 'tasks']}/> <span className="navLabel">{t('main.link.orgs')}</span>
                        </NavLink>
                    </li>
                }
                {
                    features.includes('staff') &&
                    hasPermission('staff_view') &&
                    <li>
                        <NavLink to="/staff"
                                 className={({ isActive }) => "leftNavConLinks" + (isActive ? " active-nav" : "")}
                                 onClick={props.menuCallback}>
                            <FontAwesomeIcon icon={['fas', 'id-card-alt']} /> <span className="navLabel">{t('main.link.staff')}</span>
                        </NavLink>
                    </li>
                }
                {
                    features.includes('usergroups') &&
                    hasPermission('usergroups_view') &&
                    <li>
                        <NavLink to="/usergroups"
                                 className={({ isActive }) => "leftNavConLinks" + (isActive ? " active-nav" : "")}
                                 onClick={props.menuCallback}>
                            <FontAwesomeIcon icon={['fas', 'user-friends']} /> <span className="navLabel">{t('main.link.usergroups')}</span>
                        </NavLink>
                    </li>
                }
                {
                    features.includes('clients') &&
                    hasPermission('clients_view') &&
                    <li>
                        <NavLink to="/clients"
                                 className={({ isActive }) => "leftNavConLinks" + (isActive ? " active-nav" : "")}
                                 onClick={props.menuCallback}>
                            <FontAwesomeIcon icon={['fas', 'users']} /> <span className="navLabel">{t('main.link.clients')}</span>
                        </NavLink>
                    </li>
                }
                {
                    features.includes('features') &&
                    hasPermission('features_view') &&
                    <li>
                        <NavLink to="/features"
                                 className={({ isActive }) => "leftNavConLinks" + (isActive ? " active-nav" : "")}
                                 onClick={props.menuCallback}>
                            <FontAwesomeIcon icon={['fas', 'tools']} /> <span className="navLabel">{t('main.link.features')}</span>
                        </NavLink>
                    </li>
                }
                {
                    features.includes('features') &&
                    hasPermission('features_view') &&
                    <li>
                        <NavLink to="/permissions"
                                 className={({ isActive }) => "leftNavConLinks" + (isActive ? " active-nav" : "")}
                                 onClick={props.menuCallback}>
                            <FontAwesomeIcon icon={['fas', 'shield-alt']} /> <span className="navLabel">{t('main.link.permissions')}</span>
                        </NavLink>
                    </li>
                }
                {
                    hasPermission('ssotest_view') &&
                    <li>
                        <NavLink to="/ssotest"
                                 className={({ isActive }) => "leftNavConLinks" + (isActive ? " active-nav" : "")}
                                 onClick={props.menuCallback}>
                            <FontAwesomeIcon icon={['fas', 'cloud-upload-alt']} /> <span className="navLabel">{t('main.link.ssoTestMenu')}</span>
                        </NavLink>
                    </li>
                }
                {
                    features.includes('reports') &&
                    hasPermission('reports_view') &&
                    <li>
                        <NavLink to="/reports"
                                 className={({ isActive }) => "leftNavConLinks" + (isActive ? " active-nav" : "")}
                                 onClick={props.menuCallback}>
                            <FontAwesomeIcon icon={['fas', 'chart-pie']} /> <span className="navLabel">{t('main.link.reports')}</span>
                        </NavLink>
                    </li>
                }
                {
                    features.includes('event_notifications') &&
                    hasPermission('event_notification_view') &&
                    <li>
                        <NavLink to="/eventnotifications"
                                 className={({ isActive }) => "leftNavConLinks" + (isActive ? " active-nav" : "")}
                                 onClick={props.menuCallback}>
                            <FontAwesomeIcon icon={['fas', 'circle-exclamation']} /> <span className="navLabel">{t('main.link.eventNotifications')}</span>
                        </NavLink>
                    </li>
                }
                {
                    features.includes('settings') &&
                    hasPermission('settings_view') &&
                    <li>
                        <NavLink to="/settings"
                                 className={({ isActive }) => "leftNavConLinks" + (isActive ? " active-nav" : "")}
                                 onClick={props.menuCallback}>
                            <FontAwesomeIcon icon={['fas', 'cogs']} /> <span className="navLabel">{t('main.link.settings')}</span>
                        </NavLink>
                    </li>
                }
                {
                    features.includes('assets') &&
                    hasPermission('assets_view') &&
                    <li>
                        <NavLink to='/assets'
                                 className={({ isActive }) => "leftNavConLinks" + (isActive ? " active-nav" : "")}
                                 onClick={props.menuCallback}>
                            <FontAwesomeIcon icon={['fas','photo-video']}/> <span className="navLabel">{t('main.link.assets')}</span>
                        </NavLink>
                    </li>
                }
                <li>
                    <div className="leftNavConLinks" onClick={logout}>
                        <FontAwesomeIcon icon={['fas', 'sign-out-alt']}/> <span className="navLabel">Logout</span>
                    </div>
                </li>
            </ul>
        </nav>
    );
}

export default AdminNavigation;