import React, {createContext,useReducer} from 'react';
import alertReducer from "./alertReducer";
import {SET_ALERT,REMOVE_ALERT} from "./alertTypes";
import AlertContext from './alertContext';
const AlertState = props => {

    const initialState = {
        alert: {
            msg:'',
            type:'',
            dismissible: false
        },
        show: false
    };
    const [state,dispatch] = useReducer(alertReducer,initialState);


    const setAlert = (msg,type = '',dismissible = false,timeout = 30000) => {

        dispatch({
            type:SET_ALERT,
            payload:{msg,type,dismissible}
        })

        if (dismissible) setTimeout(() => removeAlert(),timeout)
    }

    const removeAlert = () => dispatch({type:REMOVE_ALERT})

    return (
        <AlertContext.Provider value={{
            alert:state.alert,
            show:state.show,
            setAlert,
            removeAlert
        }}>
            {props.children}
        </AlertContext.Provider>
    )
};

export default AlertState;