import React, {useEffect} from 'react';
import Cookies from "universal-cookie/lib";
import {useMedical} from "../context/medical/MedicalProvider";
import {useNavigate, useParams} from "react-router-dom";
import Timer from "./Timer";
import ModalWindow from "../ModalWindow";

/**
 * @desc for routes that are only accessible when a user is authenticated
 * @param Component
 * @returns {JSX.Element}
 * @constructor
 */
const MedicalAuthRoute = ({component:Component}) => {

    const {user,facilityId} = useMedical();
    const navigate = useNavigate();
    const cookies = new Cookies();
    const params = useParams();
    useEffect(() => {
        if (cookies.get('medicalToken') === undefined ) {
            if (!user) {
                if (facilityId) {
                    if ((facilityId !== params.facilityId) && params.facilityId !== undefined) {
                        navigate(`/facility/${params.facilityId}`);
                    }
                    else {
                        navigate(`/facility/${facilityId}`);
                    }
                }
                else {
                    navigate(`/facility/${params.facilityId}`);
                }

            }
        }
    },[user])

    return (
        <>
            <ModalWindow/>
            <Timer medicalTimer/>
            <Component/>
        </>
    );
};

export default MedicalAuthRoute;