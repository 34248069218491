import React,{useReducer} from 'react';
import UserContext from './userContext';
import UserReducer from './userReducer';
import {useApi} from "../../hooks/useApi";
import Cookies from "universal-cookie/lib";
import axios from "axios";
import {HC_API} from "../../utils/params";

const cookies = new Cookies();

const UserState = props => {

    const initialState = {
        user: null
    }

    const {api} = useApi();
    const [state,dispatch] = useReducer(UserReducer,initialState);

    const hasPermission = permission =>
    {
        return state.user !== null &&
               Array.isArray(state.user.permissions) &&
               state.user.permissions.includes(permission);
    }

    const setUser = async () =>
    {
        try
        {
            if (state.user !== null) return;

            let user = cookies.get('user');
            if (user === undefined)
            {
                const res = await api({route:'user/info'});
                user = res.data.user;
                let preferences = user.preferences;
                let dashboard = user.dashboard || [];
                delete user.preferences;
                delete user.dashboard;
                cookies.set('user', user,{path:'/'})
                cookies.set('preferences', preferences, {path: '/'})
                if (user.interfaceType !== undefined)
                    cookies.set('interfaceType', user.interfaceType, {path: '/'});

                // Push dashboard layout into local storage
                if (global.localStorage && (dashboard !== undefined || Object.keys(dashboard).length > 0)) {
                    global.localStorage.setItem(
                        "rgl-8",
                        JSON.stringify(dashboard.layout)
                    );
                }

                if (preferences !== undefined && preferences.theme !== 'light') window.location.reload();

            }

            dispatch({type: 'SET_USER', payload: user });
        }
        catch (e)
        {
            console.error(e);
        }
    }


    const updateUser = user => {
        dispatch({
            type: 'UPDATE_USER',
            payload:user
        });
    }

    const logout = async () => {
        const urlParams = new URLSearchParams(location.search);
        const confirmation_code = urlParams.get('confirmation_code');
        const user = state.user;

        if (!confirmation_code && user != null) {
            try {
                let data = {};
                let headers = {};

                const url = HC_API + 'user/logout';
                if (!('Authorization' in headers)) {
                    headers.Authorization = 'Cognito ' + cookies.get('awst');
                }

                headers['Content-Type'] = 'application/json';

                data.remote_uri = window.location.href;

                const config = {
                    headers
                }

                await axios.post(url, data, config);
            } catch (e) {
                console.log("Logout Error: ", e)
            }
        }

        dispatch({
            type:'LOGOUT'
        })
        if (!confirmation_code) {
            let currentUrl = window.location;
            window.location.assign(currentUrl);
        }
    }


    return (
        <UserContext.Provider value={{
            user:state.user,
            setUser,
            logout,
            updateUser,
            hasPermission
        }}>
            {props.children}
        </UserContext.Provider>
    )
}


export default UserState;