import React, {useEffect} from 'react';
import '../../css/medical/css/medicalStyle.css';
import '../../css/medical/css/utilities.css';
import {useApi} from "../../hooks/useApi";
import {useLocation, useNavigate} from "react-router-dom";
import LandingHeader from "./LandingHeader";
import {useMedical} from "../../context/medical/MedicalProvider";
import Form from 'react-bootstrap/Form';
import Alerts from "../../Alerts";
import useAlert from "../../hooks/useAlert";
import Cookies from "universal-cookie/lib";

const MedicalLandingPage = () => {
    const navigate = useNavigate();
    const {api} = useApi();
    const {setAlert,removeAlert} = useAlert();
    const cookies = new Cookies();
    const {
        setInfo,
        setDisplayHeader,
        validateFacility,
    } = useMedical();

    const handleSubmit = async e => {
        try {
            e.preventDefault();
            const {facilityId} = e.target.elements;
            const id = facilityId.value;
            const data = await validateFacility(id);
            const {isValidated,displayName,groupName,paymentUrl} = data;
            if (isValidated) {
                setInfo({
                    displayName,
                    groupName,
                    paymentUrl,
                    facilityId: id
                });
                cookies.set('facilityId', id, {path: '/'});
                navigate(`/facility/${id}`);
            } else {
                setAlert('Enter a valid Facility Code','danger')
            }
        } catch (e) {
            console.error(e);
        }
    }

    useEffect(()=>{
        setDisplayHeader(false);

        return () => {
            removeAlert();
        }
    },[])

    return (
        <>
            <LandingHeader/>
            <div className="medicalContainer hc-center-md">
                <Alerts/>
                <div className="medicalContainerBodyTitle">
                    <h4>Enter the Facility Code</h4>
                </div>
                <Form className="medicalFormGroup" onSubmit={handleSubmit}>
                    <div className={'urlForm'}>
                        <span className={'url blink'}>{'https://patient.statement.services/ '}</span>
                        <Form.Control id="facilityId" type="text"/>
                    </div>
                    <input id={'facilitySubmit'} className={'medicalBtn medicalBtn-primary'} type="submit" value={'Submit'}/>
                </Form>
                <p>Facility code can be found on your Statement:</p>
                <img className={'facilityImage'} src="../../assets/images/facility_help1.png" alt="img1"/>
                <p>-- OR --</p>
                <h4>Scan the QR Code</h4>
                <img className={'facilityImage'} src="../../assets/images/facility_help2.png" alt="img2"/>
                <div className="facilityLandingDescription">
                    <p>Having trouble? Need support?</p>
                    <p>Feel free to call us: +1 800 225 0953</p>
                </div>
            </div>
        </>
    );
};

export default MedicalLandingPage;